import { useEffect, useState } from 'react';
import styles from './style.module.scss';
import HybridTooltip from 'components/ui/hybrid-tooltip';

export default function InputText({
  label = '',
  id,
  name,
  type = 'text',
  min,
  max,
  disabled,
  placeholder = '',
  placeholderText = '',
  description = '',
  value = '',
  required = false,
  fullWidth = false,
  onChange,
  onChangeDate = (a, b) => {},
  onError = (a, b) => {},
  hideRequiredMessage = false,
  checkErrorOnFirstRender = false,
  ignoreError = false,
  wasChanged,
  icon,
  icon_content,
  highlight,
  invalidNumberInput,
  broadcom_fill = false,
  offering = false,
  autoFocus = false,
  forecast = false,
  maxLength = undefined,
  errorInput = '',
  onBlur,
  maxWords
}: {
  label?: string;
  id?: string;
  name?: string;
  type?: string;
  min?: any;
  max?: any;
  disabled?: boolean;
  placeholder?: string;
  placeholderText?: string;
  description?: string;
  value: any;
  required?: boolean;
  fullWidth?: boolean;
  onChange?: (a) => void;
  onChangeDate?: (a, b) => void;
  onError?: (a, b) => void;
  hideRequiredMessage?: boolean;
  checkErrorOnFirstRender?: boolean;
  ignoreError?: boolean;
  wasChanged?: boolean;
  icon?: any;
  icon_content?: any;
  highlight?: boolean;
  invalidNumberInput?: any;
  broadcom_fill?: boolean;
  offering?: boolean;
  autoFocus?: boolean;
  forecast?: boolean;
  maxLength?: number;
  errorInput?: any;
  onBlur?: () => void;
  maxWords?: number;
}) {
  const [checkError, setCheckError] = useState(checkErrorOnFirstRender);
  const maxWordsCheck = maxWords
    ? value?.trim()?.split(' ').length > maxWords
    : false;

  const [error, setError] = useState(
    String(errorInput).trim() !== '' ||
      (checkErrorOnFirstRender && required && String(value).trim() === '')
  );

  useEffect(() => {
    const hasError =
      (required && String(value).trim() === '') ||
      invalidNumberInput?.includes(id);
    setError(checkError && hasError && !ignoreError);
    onError && onError(hasError && !ignoreError, id);
    setCheckError(true);
  }, [value]);

  useEffect(() => {
    setError(String(errorInput).trim() !== '');
  }, [errorInput]);

  return (
    <fieldset
      className={`${styles.fieldset} ${fullWidth ? styles.full_width : ''} ${
        error ? 'error' : ''
      } ${highlight ? styles.highlight : ''}`}
    >
      <label className="flex items-center" htmlFor={id}>
        {label}
        {broadcom_fill && (
          <span className={'text-red-500 ml-[2px] leading-[18px] text-[16px]'}>
            *
          </span>
        )}
        {required && (
          <span className={'text-red-500 ml-[2px] leading-[18px] text-[16px]'}>
            *
          </span>
        )}
        {icon && <HybridTooltip text={icon_content} />}
      </label>
      {description && <small> {description}</small>}
      <input
        className={`${!value ? styles.not_filled : ''} ${
          wasChanged && styles.changed
        }`}
        id={id}
        name={name}
        type={type}
        min={min}
        max={max}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        onWheel={(e: any) => e.target.blur()}
        onBlur={onBlur}
        maxLength={maxLength}
      />
      {error && !hideRequiredMessage && !invalidNumberInput?.includes(id) && (
        <span>
          {maxWordsCheck
            ? 'Words limit exceeded'
            : type !== 'date' && 'Required field'}
          {type === 'date' && errorInput}
        </span>
      )}
    </fieldset>
  );
}
