'use client';
import { signOut } from 'next-auth/react';
import styles from './style.module.scss';
import Arrow from 'public/images/rounded-down-arrow.svg';
import NoNewNotif from 'public/images/no_notif.svg';
import News from 'public/images/news.svg';
import { useState, useEffect, useRef, useContext } from 'react';
import PartnersContext from 'context/PartnerListingsProvider';
import { useTranslation } from 'react-i18next';
import useOutsideClickMultiple from 'hooks/useOutsideClickMultiple';
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger
} from 'components/ui/popover';
import { toast } from 'sonner';
import { Button } from 'components/ui/button';
import { Command, CommandGroup, CommandItem } from 'components/ui/command';
import Bell from 'public/images/bell.svg';
import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from 'services/axios';
import Loader from 'components/atoms/Loader';
import { LanguageSwitcher } from 'components/organisms/LanguageSwitcher';
import ReleaseModal from 'components/molecules/Modal/ReleaseModal';
import { Checkbox } from 'components/ui/checkbox';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem
} from 'lib/localStorage';
import { capitalize, timeAgo } from 'shared/utils';
import i18next from 'i18next';
import { cn } from 'lib/utils';
import { VscCheck } from 'react-icons/vsc';
import { buildNotificationTitle } from 'shared/partnerPublic';
import useRouterStuff from 'hooks/useRouterStuff';
import { useAuthContext } from 'hooks/useContexts';
import {
  DASHBOARD_PAGE_URL,
  SITE_ADMINISTRATION_DEFAULT_TAB,
  SITE_ADMINISTRATION_PAGE_URL
} from 'utils/constants';

type Partner = {
  id: number,
  slug: string
};

type Nots = {
  action: string,
  createdAt: string,
  dateSolved: string,
  id: number,
  identifier: number,
  isSolved: boolean,
  type: string,
  userSolved: string
};

type UserNotificationsQueryResultType = {
  numberNewNots: number,
  numberAllNots: number,
  new: Nots[],
  all: Nots[],
  ids: {
    PARTNER: Partner[]
  },
  hasAnySolved: boolean
};

export default function UserActions() {
  const ref = useRef();
  const refNotification = useRef<HTMLDivElement>(null);
  const { router, pathname } = useRouterStuff();
  const { user, isSiteAdmin } = useAuthContext();

  const role = user?.roles?.rolesBroadcomContact[0]?.roles?.name;
  const [showTooltip, setShowTooltip] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [refetchNots, setRefetchNots] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [notificationTabSelected, setNotificationTabSelected] = useState('new');
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [takeNumNewNotif, setTakeNumNewNotif] = useState(5);
  const [takeNumAllNotif, setTakeNumAllNotif] = useState(5);
  const [notsMarkedAsRead, setNotsMarkedAsRead] = useState<number[]>([]);
  const [localLoading, setLocalLoading] = useState(false);

  const { isLoadingMenu, setIsLoadingMenu, setLoadingInfo } = useContext(PartnersContext);
  const { t } = useTranslation();

  const localStorageSession = getLocalStorageItem('session');
  const localStorageClickedState = getLocalStorageItem('clicked');

  const siteAdministrationUrl = `/${SITE_ADMINISTRATION_PAGE_URL}/${SITE_ADMINISTRATION_DEFAULT_TAB}`;

  const handleSignOut = async () => {
    removeLocalStorageItem('session');
    removeLocalStorageItem('clicked');
    await signOut();
    window.location.href = `https://${process.env.OKTA_DOMAIN}/v1/logout?id_token_hint=${user.idToken}&post_logout_redirect_uri=${process.env.NEXTAUTH_URL}`;
  };

  const options = [
    {
      key: 1,
      text: t('dashboard'),
      onClick: () => {
        setIsLoadingMenu(true);
        router.push(`/${DASHBOARD_PAGE_URL}`);
      },
      hidden:
        pathname?.startsWith(`/${DASHBOARD_PAGE_URL}`) &&
        !pathname?.startsWith(`/${DASHBOARD_PAGE_URL}/partner/`)
    },
    {
      key: 2,
      text: t('home_page'),
      onClick: () => {
        setLocalLoading(true);
        router.push('/');
      },
      hidden:
      !pathname?.startsWith(`/${DASHBOARD_PAGE_URL}`) &&
      !pathname?.startsWith(siteAdministrationUrl)
    },
    {
      key: 3,
      text: t('site_admin'),
      onClick: () => {
        setLocalLoading(true);
        router.push(siteAdministrationUrl);
      },
      hidden: !isSiteAdmin || pathname?.startsWith(siteAdministrationUrl)
    },
    { key: 4, text: t('sign_out'), onClick: handleSignOut }
  ];

  useOutsideClickMultiple([ref], () => setShowTooltip(true));
  useOutsideClickMultiple([refNotification], () =>
    setIsNotificationOpen(false)
  );

  const { data: number_of_notif } = useQuery<number>({
    queryKey: ['notifications', refetch],
    queryFn: async () => {
      if (!user?.email) {
        return 0;
      }

      setIsLoading(true);
      let nots = 0;
      const allReleases: any = await getAPIClient().get(`/releases`);
      const userReleases: any = await getAPIClient().get(
        `/releases/${user.email}`
      );

      const lastNotific = userReleases.data.lastRelease;

      let uniqueReleaseDates: any[] = [];

      allReleases.data.forEach((obj) => {
        const { releaseDate } = obj;

        const existingItem: any = uniqueReleaseDates.find(
          (item: any) => item.releaseDate === releaseDate
        );

        if (existingItem) {
          existingItem.data.push(obj);
        } else {
          uniqueReleaseDates.push({ releaseDate, data: [obj] });
        }
      });

      uniqueReleaseDates = uniqueReleaseDates.map(
        (rel) =>
          rel.releaseDate > lastNotific &&
          rel.data.map(
            (f) =>
              f?.roles?.length === 0 ||
              f?.roles.map((obj) => obj.name).includes(role) ||
              isSiteAdmin
          ) &&
          nots++
      );
      setIsLoading(false);
      const result = nots;
      return result;
    },
    refetchOnWindowFocus: true
  });
  // const { data: userNotifications, isLoading: isLoadingUserNotif } = useQuery<UserNotificationsQueryResultType>({
  //   queryKey: [
  //     'userNotifications',
  //     takeNumNewNotif,
  //     takeNumAllNotif,
  //     refetchNots
  //   ],
  //   queryFn: async () => {
  //     const notifications = await getAPIClient().get(
  //       `/notifications/${user.email}?role=${role}&takeNew=${takeNumNewNotif}&takeAll=${takeNumAllNotif}`
  //     );
  //     return notifications.data;
  //   },
  //   refetchOnWindowFocus: true,
  //   enabled: !!user
  // });

  const clickOnBell = async () => {
    await getAPIClient().put(`/releases/${user?.email}`);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isOpen) setRefetch(!refetch);
  }, [isOpen, user]);

  useEffect(() => {
    setLocalLoading(false);
  }, [pathname]);

  useEffect(() => {
    if (localStorageSession !== 'true') setLocalStorageItem('session', 'true');
  }, [user]);

  const markNotificationsAsRead = async () => {
    setLoadingInfo(true);
    const updateNotifications = await getAPIClient().put(
      `/notifications/${user?.email}?notifications=${notsMarkedAsRead}`
    );
    if (updateNotifications.status === 200) {
      setRefetchNots(!refetchNots);
      toast.success(t('nots_marked_read'));
      setNotsMarkedAsRead([]);
    } else {
      toast.success(`Error`);
    }
    setLoadingInfo(false);
  };

  const NotificationInboxEmpty = () => {
    return (
      <div className="flex flex-col w-full h-full justify-center items-center text-center gap-4">
        <NoNewNotif />
        <div>
          <h1 className="text-[#1F384C] text-sm font-[500]">
            Your notification box is empty
          </h1>
          <span className="text-[#65696D] text-xs font-[100]">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste unde
            deserunt quisquam.
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col md:flex-row gap-2 md:gap-4 items-center">
        {isLoadingMenu ? <Loader /> : null}
        {localLoading ? <Loader /> : null}
        <div className="relative">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="unstyled"
                className="text-white font-extralight text-sm p-0 h-auto tracking-normal"
              >
                {user ? `${user?.name} (${role})` : t('login')}
                <Arrow />
              </Button>
            </PopoverTrigger>
            <PopoverContent
              className="p-0 bg-[#1f384c] rounded-sm overflow-hidden"
              side="bottom"
              align="end"
            >
              <Command className="bg-transparent p-0 rounded-none">
                <CommandGroup className="p-0 text-start">
                  {options
                    ?.filter((el) => !el.hidden)
                    .map((option: any) => (
                      <CommandItem
                        key={option.key}
                        onSelect={option.onClick}
                        className="pl-4 pr-16 py-4 text-white font-normal text-xs rounded-none cursor-pointer aria-selected:bg-[#1f384c] aria-selected:text-white aria-selected:hover:bg-white aria-selected:hover:text-[#1f384c]"
                      >
                        {option.text}
                      </CommandItem>
                    ))}
                </CommandGroup>
              </Command>
              <PopoverArrow className="fill-[#1f384c] mr-24 visible" />
            </PopoverContent>
          </Popover>
          {!showTooltip &&
            localStorageClickedState !== 'true' &&
            pathname?.includes('dashboard') && (
              <div className={styles.filters_help_tooltip}>
                {t('please_logout')}
              </div>
            )}
        </div>
        <LanguageSwitcher />
        {/* <span
          className={styles.bell}
          onClick={() => setIsNotificationOpen((prev) => !prev)}
        >
          <Bell />
          {userNotifications && userNotifications?.numberNewNots > 0 && !isLoading ? (
            <span className={styles.red_circle}>
              {userNotifications?.numberNewNots}
            </span>
          ) : (
            ''
          )}
        </span> */}
        <span className={styles.bell} onClick={clickOnBell}>
          <News />
          {number_of_notif && number_of_notif > 0 && !isLoading ? (
            <span className="h-[10px] bg-[#FCA652] text-white rounded-full px-[5px] py-[2px] absolute left-[15px] text-[9px] font-light">
              {number_of_notif}
            </span>
          ) : (
            ''
          )}
        </span>
        {/* <div
          ref={refNotification}
          className={cn(
            'absolute top-[9rem] md:top-10 md:w-[437.2px] bg-white w-[350px] h-[400px] z-[9999999] shadow-2xl rounded-md mt-4 ',
            isNotificationOpen ? 'flex flex-col' : 'hidden', pathname?.includes('site-administration') ? 'md:top-[10rem]' : ''
          )}
        >
          <div className="w-full h-[33px] flex justify-between border-t-0 border-b-[1px] border-l-0 border-r-0 border-solid border-[#E2E7EB] pb-3">
          <div className="h-full flex p-2 items-center">
              <div
                className={cn('flex gap-2 ml-4 cursor-pointer items-center')}
                onClick={() => setNotificationTabSelected('new')}
              >
                <span
                  className={cn(
                    'flex items-center gap-2.5 text-[#1F384C] text-sm leading-none',
                    notificationTabSelected === 'new'
                      ? 'text-[#1F384C] font-medium'
                      : 'text-[#ADB2B6] font-[100]'
                  )}
                >
                  {userNotifications && userNotifications?.numberNewNots > 0 && (
                    <Checkbox
                      id='select_all'
                      key="select_all"
                      checked={userNotifications?.new?.every((notif) =>
                        notsMarkedAsRead.includes(notif.id)
                      )}
                      onCheckedChange={() => {
                        setNotsMarkedAsRead((prevIds) => {
                          if (
                            userNotifications?.new?.every((notif) =>
                              notsMarkedAsRead.includes(notif.id)
                            )
                          )
                            return [];
                          else
                            return [
                              ...prevIds,
                              ...userNotifications?.new
                                ?.filter(
                                  (notif) =>
                                    !notsMarkedAsRead.includes(notif.id)
                                )
                                .map((notif) => notif.id)
                            ];
                        });
                      }}
                    />
                  )}
                  {t('new')}
                </span>
                {userNotifications && userNotifications?.numberNewNots > 0 && (
                  <span className="text-white font-[100] text-[12px] leading-none bg-[#CC0830] rounded-full w-4 h-4 flex justify-center items-center p-px">
                    {userNotifications?.numberNewNots}
                  </span>
                )}
              </div>
              <span className="text-[#ADB2B6] font-medium text-sm ml-4 leading-none">
                {' '}
                |{' '}
              </span>
              <span
                className={cn(
                  'text-[#1F384C] text-sm ml-4 leading-none cursor-pointer',
                  notificationTabSelected === 'all'
                    ? 'text-[#1F384C] font-medium'
                    : 'text-[#ADB2B6] font-[100]'
                )}
                onClick={() => setNotificationTabSelected('all')}
              >
                {t('all')}
              </span>
            </div>
            {notificationTabSelected === 'new' && (
              <div className="h-full pr-2 mr-2 flex cursor-pointer">
                <span
                  className="text-[#1C4D80] font-[300] text-xs mt-3 leading-none flex items-center gap-2"
                  onClick={() => markNotificationsAsRead()}
                >
                  {(userNotifications?.hasAnySolved ||
                    notsMarkedAsRead.length > 0) && (
                    <>
                      <VscCheck strokeWidth={1} />
                      {notsMarkedAsRead.length > 0
                        ? t('nots_selected_read')
                        : t('nots_resolved_read')}
                    </>
                  )}
                </span>
              </div>
            )}
          </div>
          <div className="flex flex-col gap-6 py-4 h-[390px] w-full overflow-y-auto">
            {notificationTabSelected === 'new' ? (
              userNotifications && userNotifications?.new?.length > 0 ? (
                userNotifications?.new?.map((item) => (
                  <div className="flex items-start ml-4">
                    <Checkbox
                      key={item.id}
                      id={`${item.id}`}
                      checked={notsMarkedAsRead.includes(item.id)}
                      onCheckedChange={() => {
                        setNotsMarkedAsRead((prevIds) => {
                          const index = prevIds.indexOf(item.id);
                          if (index !== -1) {
                            const updatedIds = [...prevIds];
                            updatedIds.splice(index, 1);
                            return updatedIds;
                          }
                          return [...prevIds, item.id];
                        });
                      }}
                    />
                    <a
                      className="cursor-pointer no-underline"
                      href={
                        ['ONBOARDING', 'PARTNER_REVIEW'].includes(item.action)
                          ? `dashboard?tab=partners&subTab=${item.action.toLowerCase()}&pagination=0`
                          : `/dashboard/partner/${
                              userNotifications?.ids?.PARTNER?.find(
                                (p) => p.id === item.identifier
                              )?.slug
                            }?tab=ppc&page=view`
                      }
                    >
                      <div key={item.id} className="">
                        <div className="px-4 flex items-center gap-4">
                          <div className="w-2 h-2 bg-[#6FCF97] rounded-full aspect-square" />
                          <span className="text-[#1F384C] text-[14px] font-[500] leading-4">
                            {buildNotificationTitle({
                              resource: userNotifications?.ids,
                              ...item
                            })}
                          </span>
                          {item.isSolved && (
                            <div className="bg-[#6FCF97] text-white font-[300] text-[10px] leading-5 px-1 rounded-sm">
                              {t('resolved')}
                            </div>
                          )}
                        </div>
                        <div className="flex text-[#507492] font-[300] text-[12px] leading-6 pl-10">
                          <span>
                            {timeAgo(item.createdAt, t, i18next)} •{' '}
                            {t(capitalize(item.type))}
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
                ))
              ) : (
                <NotificationInboxEmpty />
              )
            ) : userNotifications && userNotifications?.all?.length > 0 ? (
              userNotifications?.all?.map((item) => (
                <div key={item.id} className="">
                  <div className="px-2 flex items-center">
                    <span className="text-[#1F384C] text-[14px] ml-4 font-[500] leading-4">
                      {buildNotificationTitle({
                        resource: userNotifications?.ids,
                        ...item
                      })}
                    </span>
                  </div>
                  <div className="flex text-[#507492] font-[300] text-[12px] leading-6 pl-6">
                    <span>
                      {timeAgo(item.createdAt, t, i18next)} •{' '}
                      {t(capitalize(item.type))}
                    </span>
                  </div>
                </div>
              ))
            ) : (
              <NotificationInboxEmpty />
            )}
          </div>
          {((notificationTabSelected === 'new' &&
            userNotifications && userNotifications?.numberNewNots > takeNumNewNotif) ||
            (notificationTabSelected === 'all' &&
              userNotifications && userNotifications?.numberAllNots > takeNumAllNotif)) && (
            <Button
              className="mt-2 pt-2 font-normal text-[#2360a0]"
              variant="ghost"
              onClick={() =>
                notificationTabSelected === 'new'
                  ? setTakeNumNewNotif((prev) => prev + 5)
                  : setTakeNumAllNotif((prev) => prev + 5)
              }
              loading={isLoadingUserNotif}
            >
              {t('view_more')}
            </Button>
          )}
        </div> */}
      </div>
      <ReleaseModal
        changeSetOpen={setIsOpen}
        isOpen={isOpen}
        numberOfNotif={number_of_notif}
        refetch={refetch}
        setRefetch={setRefetch}
      />
    </>
  );
}
